// 出入库管理
<template>

    <div class='inoutManage baseBg'>
        <div class="searchBar">
            <el-form :model="searchForm"
                     ref="searchForm"
                     :inline="true"
                     size="normal">
                <div v-show="activeName === '设备ID'"
                     class="mb15">
                    <el-form-item label="设备编号"
                                  prop="qrCode">
                        <el-input v-model="searchForm.qrCode"
                                  placeholder="请输入设备编号"
                                  size="normal"
                                  clearable></el-input>
                    </el-form-item>
                    <el-form-item label="设备类型"
                                  prop="typeManagerId">
                        <el-select v-model="searchForm.typeManagerId"
                                   placeholder="请选择设备类型"
                                   filterable
                                   clearable>
                            <el-option v-for="item in empTypeIdOpts"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="供应商"
                                  prop="provider">
                        <el-select v-model="searchForm.provider"
                                   placeholder="请选择供应商"
                                   :disabled="!searchForm.typeManagerId"
                                   clearable>
                            <el-option v-for="item in providerIdOpts"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.label">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="规格型号"
                                  prop="empTypeModel">
                        <el-select v-model="searchForm.empTypeModel"
                                   placeholder="请选择规格型号"
                                   :disabled="!searchForm.typeManagerId"
                                   clearable>
                            <el-option v-for="item in empTypeModelOpts"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.label">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>

                <el-form-item label="出入库类型"
                              prop="flowType">
                    <el-select v-model="searchForm.flowType"
                               placeholder="请选择出入库类型"
                               clearable>
                        <el-option v-for="item in flowTypeOpts"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="出入库方式"
                              prop="flowMethod">
                    <el-select v-model="searchForm.flowMethod"
                               placeholder="请选择出入库方式"
                               clearable>
                        <el-option v-for="item in flowMethodOpts"
                                   :key="item.code"
                                   :label="item.label"
                                   :value="item.code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="出入库日期"
                              prop="timeArr">
                    <el-date-picker v-model="timeArr"
                                    type="daterange"
                                    value-format="yyyy-MM-dd"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary"
                               @click="getList">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type=""
                               @click="resetSearchForm">清除</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="listBox">
            <el-tabs v-model="activeName"
                     @tab-click="handleClick">
                <el-tab-pane label="记录编号"
                             name="记录编号">
                    <div class="tableBox">
                        <el-table :data="tableData.data"
                                  border
                                  :height="tableHeight"
                                  stripe>
                            <el-table-column v-for="col in tableData.columns"
                                             :prop="col.id"
                                             :key="col.id"
                                             :label="col.label"
                                             :min-width="col.width">
                            </el-table-column>
                            <el-table-column prop="remark"
                                             label="备注"
                                             min-width="400px">
                                <template slot-scope="scope">
                                    <span v-html="commonJs.conversionHtml(scope.row.remark)"></span>
                                </template>
                            </el-table-column>

                            <el-table-column prop="id"
                                             label="操作"
                                             min-width="100">
                                <template slot-scope="scope">
                                    <el-link type="primary"
                                             @click="details(scope.row)">查看</el-link>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="设备ID"
                             name="设备ID">
                    <inout-table-device-id :tableData="tableData.data"></inout-table-device-id>
                </el-tab-pane>
            </el-tabs>
            <!-- 分页 -->
            <Pagination :page="searchForm.page"
                        :limit="searchForm.size"
                        :total="pageTotal"
                        @pagination="paginationChange" />
        </div>

    </div>

</template>

<script>
import InoutTableDeviceId from "./components/inoutTableDeviceId";
import { Loading } from "element-ui";
import Pagination from "@/components/Pagination"; // 分页
import API from "@/api/stockManage.js";
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "inoutManage",

    props: [],

    components: {
        InoutTableDeviceId,
        Pagination,
    },

    data() {
        return {
            activeName: "记录编号",
            // 筛选表单
            searchForm: {
                flowType: "", // 出入库类型
                flowMethod: "", // 出入库方式
                endDate: "",
                startDate: "",
                qrCode: "", // 设备编号
                typeManagerId: "", // 设备类型
                empTypeModel: "", // 规格型号
                provider: "", // 供应商

                page: 1,
                size: 20,
            },
            pageTotal: 0, // 分页总数
            flowTypeOpts: [
                { label: "全部", value: "" },
                { label: "入库", value: "IN" },
                { label: "出库", value: "OUT" },
            ],
            flowMethodOpts: this.commonJs.getSelectByType(
                "FlowMethod",
                "flowMethodOpts",
                this
            ),
            empTypeIdOpts: [], // 设备类型下拉
            empTypeModelOpts: [], // 规格型号下拉
            providerIdOpts: [], // 供应商下拉
            timeArr: [],
            tableData: {
                columns: [
                    {
                        id: "flowCode",
                        label: "记录编号",
                        width: "180",
                    },
                    {
                        id: "flowType",
                        label: "出入库类型",
                        width: "110",
                    },
                    {
                        id: "flowMethod",
                        label: "出入库方式",
                        width: "110",
                    },
                    {
                        id: "operatorName",
                        label: "操作人",
                        width: "100",
                    },
                    {
                        id: "empCount",
                        label: "设备数量",
                        width: "100",
                    },
                    {
                        id: "createTime",
                        label: "出入库时间",
                        width: "150",
                    },
                ],
                data: [],
            },
        };
    },

    created() {},

    /* 组件缓存触发 */
    activated() {
        // 获取设备类型下拉数据
        this.getDeviceTypeOpts();
        // 获取列表
        this.getList();
    },

    mounted() {
        // 获取设备类型下拉数据
        this.getDeviceTypeOpts();
        // 获取列表
        this.getList();
    },

    methods: {
        // 获取设备类型下拉数据
        getDeviceTypeOpts() {
            commonAPI.getDeviceTypeOpts().then((res) => {
                let data = res.content;
                let opt = data.map((val) => {
                    return { label: val["label"], value: val["code"] };
                });
                this.empTypeIdOpts = this.empTypeIdOpts.concat(opt);
            });
        },
        // 获取规格型号下拉数据
        getTypeModelOpts() {
            commonAPI.getTypeModelOpts(this.searchForm.typeManagerId).then((res) => {
                let data = res.content;
                let opt = data.map((val) => {
                    return { label: val["label"], value: val["code"] };
                });
                this.empTypeModelOpts = this.empTypeModelOpts.concat(opt);
            });
        },
        // 获取供应商下拉数据
        getProviderIdOpts() {
            commonAPI
                .getProviderIdOpts({ typeManagerId: this.searchForm.typeManagerId })
                .then((res) => {
                    let data = res.content;
                    let opt = data.map((val) => {
                        return { label: val["label"], value: val["code"] };
                    });
                    this.providerIdOpts = opt;
                });
        },
        // 获取列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            if (this.activeName === "记录编号") {
                API.inoutRecordTable(this.searchForm)
                    .then((res) => {
                        loading.close();
                        this.tableData.data = res.content.records;
                        this.pageTotal = res.content.total;
                    })
                    .catch(() => {
                        loading.close();
                    });
            } else {
                API.getDeviceIDList(this.searchForm)
                    .then((res) => {
                        loading.close();
                        this.tableData.data = res.content.records;
                        this.pageTotal = res.content.total;
                    })
                    .catch(() => {
                        loading.close();
                    });
            }
        },
        // 改变分页
        paginationChange(e) {
            this.searchForm.size = e.limit;
            this.searchForm.page = e.page;
            this.getList();
        },
        // 查看详情
        details(item) {
            this.$router.push({
                path: "/stockManage/inoutDetails",
                query: { selected: item.id },
            });
        },

        // 供应商的选择
        selectTreeCustClick(node) {
            this.searchForm.provider = node.id;
        },

        // 供应商的清空
        clearProOrCust() {
            this.searchForm.provider = "";
        },
        //清空筛选条件
        resetSearchForm() {
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    this.searchForm.startDate = "";
                    this.searchForm.endDate = "";
                    this.timeArr = [];
                    this.searchForm.page = 1;
                    // 查询列表
                    this.getList();
                }
            });
        },

        // 切换tabs
        handleClick() {
            this.resetSearchForm();
            this.getList();
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 300 });
        },
    },

    watch: {
        timeArr(val) {
            this.searchForm.startDate = "";
            this.searchForm.endDate = "";
            if (val && val.length === 2) {
                this.searchForm.startDate = val[0];
                this.searchForm.endDate = val[1];
            }
        },
        "searchForm.typeManagerId": function () {
            // 获取供应商下拉数据
            this.getProviderIdOpts();
            // 获取规格型号下拉数据
            this.getTypeModelOpts();
            if (!this.searchForm.typeManagerId) {
                this.searchForm.provider = "";
                this.searchForm.empTypeModel = "";
            }
        },
    },
};
</script>

<style lang='scss' scoped>
</style>
